<template>
  <v-dialog v-model="state" persistent width="600px">
    <v-card :loading="loading">
      <v-card-title>
        <span class=" font-weight-bold blue-grey--text font"
          >Add Opening Meter Readings</span
        >
        <v-spacer></v-spacer>
      </v-card-title>
      <v-divider class="mb-4" />
      <div
        v-if="progress"
        class="d-flex flex-row flex-grow-1 justify-center pb-4"
      >
        <v-progress-circular
          color="primary"
          size="40"
          width="3"
          indeterminate
        />
      </div>
      <ValidationObserver
        v-else
        tag="div"
        class="d-flex flex-row flex-grow-1"
        v-slot="{ handleSubmit }"
      >
        <form
          class="d-flex flex-column flex-grow-1"
          @submit.prevent="handleSubmit(addOpeningMeterReading)"
        >
          <v-card-text>
            <div
              v-for="(nozzle, index) in nozzles.readings"
              :key="index"
              class="d-flex flex-row flex-grow-1 mt-n5 px-1 pt-4 grey lighten-4"
            >
              <ValidationProvider
                tag="div"
                class="d-flex flex-column"
                v-slot="{ errors }"
              >
                <v-text-field
                  dense
                  label="Fuel Name"
                  outlined
                  class="pa-1 font font-weight-medium font-size-sm"
                  v-model="nozzle.product.name"
                  readonly
                  :error-messages="errors[0]"
                />
              </ValidationProvider>
              <ValidationProvider
                tag="div"
                class="d-flex flex-column"
                v-slot="{ errors }"
              >
                <v-text-field
                  dense
                  label="Nozzle"
                  outlined
                  class="pa-1 font font-weight-medium font-size-sm"
                  v-model="nozzle.name"
                  readonly
                  :error-messages="errors[0]"
                />
              </ValidationProvider>
              <ValidationProvider
                tag="div"
                class="d-flex flex-column"
                rules="required|decimals"
                v-slot="{ errors }"
              >
                <v-text-field
                  dense
                  outlined
                  v-model="nozzle.meterReading"
                  class="pa-1 font font-weight-medium font-size-sm"
                  label="Opening Meter Reading"
                  :error-messages="errors[0]"
                />
              </ValidationProvider>
            </div>
            <div
              class=" pt-2 font font-weight-medium red--text text--accent-3 font-size-sm font-italic"
            >
              NB. Opening meter readings can only be set once
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click.stop="close" text>
              <span
                class="font text-capitalize font-weight-medium font-size-md"
              >
                Cancel
              </span>
            </v-btn>
            <v-btn
              type="submit"
              :loading="loading"
              :disabled="loading || isMeterReadingPresent"
              color="primary"
            >
              <span
                class="font text-capitalize font-weight-medium font-size-md"
              >
                Save
              </span>
            </v-btn>
          </v-card-actions>
        </form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>
<script>
import { decimals } from "../../../utils/resolvers";

import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { numeric, required } from "vee-validate/dist/rules";
import { some } from "lodash";
extend("required", {
  ...required,
  message: "Input field is required"
});
extend("numeric", {
  ...numeric,
  message: "Input field accept only numbers"
});
extend("decimals", decimals);
import { mapGetters } from "vuex";
export default {
  name: "AddNewPrice",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    state: { type: Boolean, default: false },
    loading: {
      type: Boolean,
      default: false
    },
    details: {
      type: Object,
      default: null
    },
    progress: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      resetFormState: "getResetFormState"
    })
  },
  data: () => ({
    product: {
      openingMeter: 0
    },
    isMeterReadingPresent: false,
    nozzles: {
      readings: []
    }
  }),
  watch: {
    resetFormState(value) {
      if (value) {
        this.product.openingMeter = 0;
      }
      setTimeout(() => {
        this.$store.dispatch("resetFormState", false, { root: true });
      }, 2000);
    },
    details(payload) {
      const { nozzles } = { ...payload };
      const results = nozzles?.map(nozzle => {
        return {
          id: nozzle.id,
          name: nozzle?.name,
          product: {
            id: nozzle.productId?.id,
            name: nozzle.productId?.name
          },
          meterReading: Number(nozzle?.meterReading)
        };
      });
      this.nozzles.readings = [...results];
      this.isMeterReadingPresent = some(
        results,
        item => parseInt(item.meterReading) > 0
      );
    }
  },
  methods: {
    addOpeningMeterReading() {
      const results = this.nozzles.readings.map(
        ({ id, product, meterReading }) => {
          return {
            nozzleId: id,
            productId: product?.id,
            meterReading: parseInt(meterReading)
          };
        }
      );
      this.$emit("addOpeningMeterReading", { readings: results });
    },
    close() {
      this.$emit("close", "details");
    },
    fireSnackBarMessage() {
      this.$store.commit("UPDATE_SNACKBAR", true, { root: true });
      this.$store.commit("UPDATE_MESSAGE", "Invalid value provided", {
        root: true
      });
      this.$store.commit("UPDATE_STATUS", "red", { root: true });

      setTimeout(() => {
        this.$store.commit("UPDATE_SNACKBAR", false, { root: true });
      }, 2000);
    }
  }
};
</script>
