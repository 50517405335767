var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"600px"},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_c('span',{staticClass:" font-weight-bold blue-grey--text font"},[_vm._v("Add Opening Meter Readings")]),_c('v-spacer')],1),_c('v-divider',{staticClass:"mb-4"}),(_vm.progress)?_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-center pb-4"},[_c('v-progress-circular',{attrs:{"color":"primary","size":"40","width":"3","indeterminate":""}})],1):_c('ValidationObserver',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"d-flex flex-column flex-grow-1",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addOpeningMeterReading)}}},[_c('v-card-text',[_vm._l((_vm.nozzles.readings),function(nozzle,index){return _c('div',{key:index,staticClass:"d-flex flex-row flex-grow-1 mt-n5 px-1 pt-4 grey lighten-4"},[_c('ValidationProvider',{staticClass:"d-flex flex-column",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-1 font font-weight-medium font-size-sm",attrs:{"dense":"","label":"Fuel Name","outlined":"","readonly":"","error-messages":errors[0]},model:{value:(nozzle.product.name),callback:function ($$v) {_vm.$set(nozzle.product, "name", $$v)},expression:"nozzle.product.name"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-column",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-1 font font-weight-medium font-size-sm",attrs:{"dense":"","label":"Nozzle","outlined":"","readonly":"","error-messages":errors[0]},model:{value:(nozzle.name),callback:function ($$v) {_vm.$set(nozzle, "name", $$v)},expression:"nozzle.name"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-column",attrs:{"tag":"div","rules":"required|decimals"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-1 font font-weight-medium font-size-sm",attrs:{"dense":"","outlined":"","label":"Opening Meter Reading","error-messages":errors[0]},model:{value:(nozzle.meterReading),callback:function ($$v) {_vm.$set(nozzle, "meterReading", $$v)},expression:"nozzle.meterReading"}})]}}],null,true)})],1)}),_c('div',{staticClass:" pt-2 font font-weight-medium red--text text--accent-3 font-size-sm font-italic"},[_vm._v(" NB. Opening meter readings can only be set once ")])],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.close($event)}}},[_c('span',{staticClass:"font text-capitalize font-weight-medium font-size-md"},[_vm._v(" Cancel ")])]),_c('v-btn',{attrs:{"type":"submit","loading":_vm.loading,"disabled":_vm.loading || _vm.isMeterReadingPresent,"color":"primary"}},[_c('span',{staticClass:"font text-capitalize font-weight-medium font-size-md"},[_vm._v(" Save ")])])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }